



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class InsCmsList extends Vue {
  @Prop({ default: '' }) private catKey!: string; // key值

  contents: object[] = [];

  // 获取关于cms内容列表
  getContents () {
    this.$Api.cms.getContentsByCatKeyEx({ Key: this.catKey, Page: 1, PageSize: 6, IsMobile: this.isMobile }).then((result) => {
      this.contents = result.Data;
      console.log(result, 'getContentsByCatKeyEx');
    });
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    this.getContents();
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.getContents();
  }
}
